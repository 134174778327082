import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles({
	sectionTitle: {
		marginBottom: 40,
		marginTop: 60,
	},
});

function SectionTitle(props) {
	const classes = useStyles();
	return (
		<Typography className={classes.sectionTitle} align='center' variant='h4'>
			{props.children}
		</Typography>
	);
}

export default SectionTitle;
