import React from 'react';
import {
	makeStyles,
	withStyles,
	MuiThemeProvider,
} from '@material-ui/core/styles';
import {
	Box,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Grid,
	LinearProgress,
} from '@material-ui/core';
import theme from '../../_utilities/theme';
import SectionTitle from '../../_atoms/SectionTitle';

const numeral = require('numeral');

const useStyles = makeStyles({
	goalPercentage: {
		fontWeight: 700,
		fontSize: '10rem',
	},
});

const GOAL = 10000;

// custom progress bar
const GoalProgressBar = withStyles((theme) => ({
	root: {
		height: 60,
		borderRadius: 10,
		backgroundColor: '#f4f4f4',
	},
	bar: {
		height: 60,
		backgroundColor: '#00d460',
	},
}))(LinearProgress);

// large percentage value
function LargePercentage(props) {
	const classes = useStyles();

	return (
		<React.Fragment>
			<Typography
				className={classes.goalPercentage}
				align='center'
				variant='h1'
				color='primary'
			>
				{numeral(props.totalDonations / GOAL).format('0%')}
			</Typography>
			<Typography align='center' variant='h5'>
				OF THE WAY THERE
			</Typography>
		</React.Fragment>
	);
}

function GoalProgress(props) {
	const classes = useStyles();

	let _donations = props.totalDonations || 0;
	let _progress = _donations ? (_donations / GOAL) * 100 : 0;
	let _donors = props.totalDonors || 0;

	return (
		<MuiThemeProvider theme={theme}>
			<Box className={classes.progress}>
				<SectionTitle>Road to the Match</SectionTitle>

				<Grid container spacing={2} alignItems='center'>
					<Grid item xs={12} md={6} lg={6}>
						<LargePercentage totalDonations={_donations} />
					</Grid>
					<Grid item xs={12} md={6} lg={6}>
						<GoalProgressBar variant='determinate' value={_progress} />
						<Table>
							<TableBody>
								<TableRow>
									<TableCell>Total Dollars</TableCell>
									<TableCell align='right'>
										{numeral(props.totalDonations).format('$0,0.00')}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>Total Donors</TableCell>
									<TableCell align='right'>{_donors}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>Average Donation</TableCell>
									<TableCell align='right'>
										{numeral(props.averageDonation).format('$0,0.00')}
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</Grid>
				</Grid>
			</Box>
		</MuiThemeProvider>
	);
}

export default GoalProgress;
