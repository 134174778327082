import React from 'react';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import {
	Button,
	Card,
	CardContent,
	Typography,
	CardActions,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Grid,
} from '@material-ui/core';

import theme from '../../_utilities/theme';

const numeral = require('numeral');

const useStyles = makeStyles({
	card: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
	},
	content: {
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column',
	},
	desc: {
		fontStyle: 'italic',
		marginBottom: 'auto',
	},
	bottom: {
		justifyContent: 'center',
	},
});

function CharityListing(props) {
	const classes = useStyles();
	let _donors = props.data.totalCount || 0;

	return (
		<Grid item xs={12} md={props.span} lg={4}>
			<Card className={classes.card} variant='outlined'>
				<CardContent className={classes.content}>
					<Typography variant='h5' align='center' gutterBottom>
						{props.name}
					</Typography>
					<Typography className={classes.desc}>
						{props.data.Description}
					</Typography>
					<Typography align='center' variant='h2' component='h2'>
						{numeral(props.data.totalDollars).format('$0,0.00')}
					</Typography>
					<Table className={classes.table}>
						<TableBody>
							<TableRow>
								<TableCell>Donors</TableCell>
								<TableCell align='right'>{_donors}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Average Donation</TableCell>
								<TableCell align='right'>
									{numeral(props.data.averageDollars).format('$0,0.00')}
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</CardContent>
				<CardActions className={classes.bottom}>
					<MuiThemeProvider theme={theme}>
						<Button
							color='primary'
							size='large'
							variant='outlined'
							target='_blank'
							rel='noreferrer'
							align='center'
							component='button'
							href={props.data.Link}
						>
							Donate!
						</Button>
					</MuiThemeProvider>
				</CardActions>
			</Card>
		</Grid>
	);
}

function OtherWays(props) {
	const classes = useStyles();
	return (
		<Grid item xs={12} md={6} lg={12}>
			<Card className={classes.card} variant='outlined'>
				<CardContent className={classes.content}>
					<Typography align='center' variant='h4' gutterBottom>
						Unable to Donate?
					</Typography>
					<Typography className={classes.desc} align='center' component='h2'>
						That’s okay, click to see other ways you can get involved and help
						create change.
					</Typography>
				</CardContent>
				<CardActions className={classes.bottom}>
					<MuiThemeProvider theme={theme}>
						<Button
							color='primary'
							size='large'
							variant='outlined'
							target='_blank'
							rel='noreferrer'
							align='center'
							component='button'
							href='https://blacklivesmatters.carrd.co/'
						>
							Find Out How!
						</Button>
					</MuiThemeProvider>
				</CardActions>
			</Card>
		</Grid>
	);
}

export default CharityListing;
export { OtherWays };
