import React from 'react';
import ReactDOM from 'react-dom';

import Home from './components/_views/Home';

export default function App() {
	return <Home goal={10000} />;
}

// eslint-disable-next-line no-undef
ReactDOM.render(<App />, document.getElementById('root'));
