import React from 'react';
import {
	makeStyles,
	MuiThemeProvider,
	withStyles,
} from '@material-ui/core/styles';
import {
	Container,
	Typography,
	Link,
	Grid,
	LinearProgress,
	List,
	ListItem,
} from '@material-ui/core';
import theme from '../_utilities/theme';
import Logo from '../../DP_Plus_black.84ab801b.svg';

import SectionTitle from '../_atoms/SectionTitle';
import CharityListing, { OtherWays } from '../_organisms/CharityListing';
import GoalProgress from '../_organisms/GoalProgress';

const useStyles = makeStyles({
	quote: {
		marginTop: 40,
	},
});

function Quote(props) {
	const classes = useStyles();
	return (
		<Typography component='div' className={classes.quote}>
			{props.children}
		</Typography>
	);
}

class Home extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			data: null,
		};
	}

	componentDidMount() {
		fetch(
			'https://us-central1-charity-visualization-dp.cloudfunctions.net/getBlmDonations'
		)
			.then((response) => response.json())
			.then((data) => this.setState({ data, loading: false }));
	}

	render() {
		// const { classes } = this.props;
		return (
			<MuiThemeProvider theme={theme}>
				<Container>
					{this.state.loading ? (
						<LinearProgress />
					) : (
						<React.Fragment>
							<img
								alt='DP+ Logo'
								style={{
									height: '60px',
									display: 'block',
								}}
								src={Logo}
							/>
							{/* quote */}
							<Quote>
								<p>
									We are committed to change. DP+ stands against racism in all
									its forms, and we know Black Lives Matter.
								</p>

								<p>
									Since our founding in 1997, we have had but one rule: to build
									and nurture a humanity-based culture that acknowledges and
									celebrates the value of each individual, by honoring,
									respecting and drawing upon the richness of their unique
									origins, experiences, beliefs and talents.
								</p>

								<p>
									We strive to sustain this culture each minute of each day. Not
									through words, but through actions — with each other, and with
									the people, businesses and communities we serve and support.
								</p>

								<p>
									A committee of your peers has researched local nonprofits
									working to end the injustice and inequity impacting the black
									community and people of color across the nation.
								</p>

								<p>
									As a token of our gratitude toward your commitment to change,
									DP+ will match all <em>employee</em> donations up to a total
									of $10,000.
								</p>
							</Quote>
							{/* overall progress */}
							<GoalProgress
								totalDonations={this.state.data.totalDollars}
								totalDonors={this.state.data.totalCount}
								averageDonation={this.state.data.averageDollars}
							/>
							{/* charities */}
							<SectionTitle>Donate to These Nonprofits</SectionTitle>
							<Grid container spacing={3}>
								{Object.keys(this.state.data.charities).map((item, idx) => {
									let _isLastItem =
										idx == Object.keys(this.state.data.charities).length - 1;
									let _colSpan =
										Object.keys(this.state.data.charities).length % 2 &&
										_isLastItem
											? 12
											: 6;

									return (
										<CharityListing
											key={idx}
											name={item}
											data={this.state.data.charities[item]}
											span={_colSpan}
										/>
									);
								})}
								{/* <OtherWays /> */}
							</Grid>
							{/* end copy */}
							<section>
								<SectionTitle>Match Your Donation!</SectionTitle>
								<List
									component='ol'
									style={{ listStyle: 'decimal', listStylePosition: 'inside' }}
								>
									<ListItem>
										<Typography>
											1. Donate to one or all of our three local nonprofits.
										</Typography>
									</ListItem>

									<ListItem>
										<Typography>
											2. Send a proof of donation to
											<Link href='mailto:donations@dpplus.com?subject=Donation&body=YOUR NAME%0D%0ADonation Amount: $AMOUNT%0D%0A%0D%0AAttach a copy of your receipt or screenshot.'>
												{' '}
												Mollie O’Brien-Watt{' '}
											</Link>
											with the subject line “Donation”. Include your name,
											donation amount and a copy or screenshot of your receipt.
										</Typography>
									</ListItem>
									<Typography></Typography>
									<ListItem>
										<Typography>
											3. Once your donation is verified, your match will be
											processed!
										</Typography>
									</ListItem>

									<ListItem>
										<Typography>
											4. You’ll receive confirmation that your donation has been
											matched against our $10,000 goal.{' '}
										</Typography>
									</ListItem>

									<ListItem>
										<Typography>
											5. Only current DP+ employees qualify for the match
											program. Outside donations are still appreciated by these
											organizations, so spread the word!
										</Typography>
									</ListItem>
								</List>
							</section>
						</React.Fragment>
					)}
				</Container>
			</MuiThemeProvider>
		);
	}
}

export default withStyles(useStyles)(Home);
